import { customElement } from 'lit/decorators.js';
import { style } from './sitemap.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-sitemap')
export class SbkSitemap extends LitElement {

    static get styles() {
        return style;
    }

    render() {
        return html`
            <div class="sitemap-container">
                <slot></slot>
            </div>
        `;
    }
}

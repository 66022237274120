import { SbkElement } from '../../configuration/sbk-element.ts';
import { customElement, property } from 'lit/decorators.js';
import { style } from './footer.styles';
import { html, nothing } from 'lit';


@customElement('sbk-footer')
export class SbkFooter extends SbkElement {

    @property({type: String, attribute: 'copyright-text'})
    copyrightText = '';

    @property({type: String, attribute: 'logo-path'})
    logoPath = '';

    @property({type: String, attribute: 'logo-alt-text'})
    logoAltText = '';

    @property()
    variant: 'default' | 'reduced' = 'default'

    static get styles() {
        return style;
    }

    render() {
        return html`
            <footer class="footer--${this.variant}">
                <div class="footer-wrapper">
                    ${this.variant === 'reduced' ? nothing : this.renderExtendedVersion()}
                    <slot name="serviceNavigation" class="service-navigation"></slot>
                    <div part="copyrightText">
                        <p class="copyright">&copy; ${this.copyrightText}</p>
                    </div>
                </div>
            </footer>
        `;
    }

    renderExtendedVersion() {
        return html`
                <slot name="socialMediaIcons" class="social-media-icons"></slot>
                <slot name="sitemap" class="footer-sitemap"></slot>
                <div class="logo-wrapper">
                    <img class="logo" src=${this.logoPath} alt=${this.logoAltText} />
                </div>
        `;
    }
}

import { customElement, property } from 'lit/decorators.js';
import {html, LitElement} from 'lit';
import {style} from "@/components/sitemap-item/sitemap-item.styles";


@customElement('sbk-sitemap-item')
export class SbkSitemapItem extends LitElement {

    @property()
    href = '';

    @property()
    icon = '';

    static get styles() {
        return style;
    }

    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('role', 'listitem');
    }

    render() {
        return html`
            <div>
                <sbk-link href=${this.href} variant="regular" size="xs" icon=${this.icon}>
                    <slot></slot>
                </sbk-link>
            </div>
        `;
    }
}

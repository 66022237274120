import {css} from "lit";

export const style = css`
  
  * {
    box-sizing: border-box;
  }
  
  .sitemap-column-header {
    display: flex;
    color: var(--sitemap-column-header__color);
    font: var(--sitemap-column-header__font);
  }

  .sitemap-column-header {
    display: flex;
  }
`;

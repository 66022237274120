import {css, unsafeCSS} from "lit";
import { buttonOnDarkStyles } from '@/components/button/button--onDark.styles'
import { linkOnDarkStyles } from '@/components/link/link--onDark.styles'
import { sbkCoreBreakpointMd, sbkCoreBreakpointLg, sbkCoreBreakpointXl } from '@/tokens/variables.js'

const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);


export const style = css`
    :host {
        --footer-bg: var(--_footer-bg, var(--sbkFooterBgColorDefault));
        --footer-copyright__color: var(--_footer-copyright__color, var(--sbkFooterFgColorSubtle));
        --footer-logo__height: var(--_footer-logo__height, var(--sbkFooterSizingLogoHeight));
        --footer-logo__color: var(--_footer-logo__color, var(--sbkCoreColorLightBlue800));
        --footer__inset-top--sm: var(--_footer__inset-top--sm, var(--sbkFooterSmallScrInsetTop));
        --footer__inset-bottom--sm: var(--_footer__inset-bottom--sm, var(--sbkFooterSmallScrInsetBottom));
        --footer__gap--sm: var(--_footer__gap--sm, var(--sbkFooterSmallScrGap));
        --footer-link-container__gap--sm: var(--_footer-link-container__gap--sm, var(--sbkCoreSizing3xs));
        --footer__padding: var(--_footer__padding, var(--sbkCoreSizingMd));
        --footer__inset-top--lg: var(--_footer__inset-top--lg, var(--sbkFooterLargeScrInsetTop));
        --footer__inset-bottom--lg: var(--_footer__inset-bottom--lg, var(--sbkFooterLargeScrInsetBottom));
        --footer__gap--lg: var(--_footer__gap--lg, var(--sbkFooterLargeScrGap));
    --footer-link-container__gap-lg: var(--_footer-link-container__gap-lg, var(--sbkFooterLargeScrLinkContainerGap));
    ${buttonOnDarkStyles}
    ${linkOnDarkStyles}
  }

  footer {
    background-color: var(--footer-bg);
    color: white;
      
      .footer-wrapper {
          display: flex;
          flex-direction: column;
          gap: var(--footer__gap--sm);
          padding: var(--sbkFooterSmallScrInsetTop) var(--sbkCoreGridSmMargin) var(--sbkFooterSmallScrInsetBottom);

          @media all and (min-width: ${breakpointMd}) {
              padding: var(--sbkFooterSmallScrInsetTop) var(--sbkCoreGridMdMargin) var(--sbkFooterSmallScrInsetBottom);
          }

          @media all and (min-width: ${breakpointLg}) {
              padding: var(--sbkFooterLargeScrInsetTop) var(--sbkCoreGridLgMargin) var(--sbkFooterLargeScrInsetBottom);
          }

          @media all and (min-width: ${breakpointXl}) {
              padding: var(--sbkFooterLargeScrInsetTop) var(--sbkCoreGridXlMargin) var(--sbkFooterLargeScrInsetBottom);
          }
      }
  }

  .social-media-icons::slotted(div) {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: var(--footer-link-container__gap--sm);
  }

  .logo-wrapper {
    display: flex;
    justify-content: left;
  }

  .logo {
    height: var(--footer-logo__height);
    width: auto;
  }

  .service-navigation::slotted(*) {
    justify-content: left;
  }

  .copyright {
      margin: 0;
      text-align: left;
      font: var(--sbkTypographyContentLargeScrBodyRegularXs);
      color: var(--footer-copyright__color);
  }

  @media screen and (min-width: ${breakpointMd}) {
    .social-media-icons::slotted(div) {
      gap: var(--footer-link-container__gap-lg);
    }
  }

  @media screen and (min-width: ${breakpointLg}) {
    footer {
      gap: var(--footer__gap--lg);
    }
    .social-media-icons::slotted(div) {
      justify-content: center;
    }
    .logo-wrapper {
      justify-content: center;
    }
    .copyright {
      text-align: center;
    }
  }
    
    .footer--reduced .footer-wrapper {
        gap: var(--sbkFooterSmallScrReducedGap);
        padding: var(--sbkFooterSmallScrReducedInsetTop) var(--sbkCoreGridSmMargin) var(--sbkFooterSmallScrReducedInsetBottom);
        @media all and (min-width: ${breakpointMd}) {
            padding: var(--sbkFooterSmallScrReducedInsetTop) var(--sbkCoreGridMdMargin) var(--sbkFooterSmallScrReducedInsetBottom);
        }

        @media all and (min-width: ${breakpointLg}) {
            padding: var(--sbkFooterLargeScrReducedInsetTop) var(--sbkCoreGridLgMargin) var(--sbkFooterLargeScrReducedInsetBottom);
            flex-direction: row;
            justify-content: space-between;
            align-items: baseline;
        }

        @media all and (min-width: ${breakpointXl}) {
            margin: 0 auto;
            max-width: var(--sbkCoreContentAreaMaxWidth);
            padding: var(--sbkFooterLargeScrReducedInsetTop) var(--sbkCoreGridXlMargin) var(--sbkFooterLargeScrReducedInsetBottom);
        }
    }
`;

import {css, unsafeCSS} from "lit";
import {sbkCoreBreakpointLg} from '@/tokens/variables.js';

const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);

export const style = css`
  :host {
    --sitemap__max-width: var(--_sitemap__max-width, var(--sbkCoreContentAreaMaxWidth));
    --footer-link-container__gap--sm: var(--_footer-link-container__gap--sm, var(--sbkFooterSmallScrLinkContainerGap));
    --footer-link-container__gap--md: var(--_footer-link-container__gap--md, var(--sbkCoreSizingMd));
    --footer-link-container__gap--lg: var(--_footer-link-container__gap--lg, var(--sbkCoreSizingXl));
  }

  .sitemap-container {
    display: flex;
    flex-wrap: wrap;
      justify-content: space-between;
    gap: var(--footer-link-container__gap--sm);
    max-width: var(--sitemap__max-width);
      margin: 0 auto;
  }

  @media screen and (min-width: ${breakpointLg}) {
    .sitemap-container {
      gap: var(--footer-link-container__gap--md);
    }
  }

  @media screen and (min-width: ${breakpointLg}) {
    .sitemap-container {
      gap: var(--footer-link-container__gap--lg);
    }
  }
`;

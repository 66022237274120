import { customElement, property, state } from 'lit/decorators.js';
import { style } from './sitemap-column.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-sitemap-column')
export class SbkSitemapColumn extends LitElement {

    @property()
    header = '';

    @state()
    _expanded = false;

    static get styles() {
        return style;
    }

    _toggle() {
        this._expanded = !this._expanded
    }

    render() {
        return html`
            <dl>
                <dt>
                    <button id="accordion-header"
                            class="sitemap-column-header sitemap-column-header-mobile"
                            @click=${this._toggle}
                            aria-controls="sitemap-panel"
                            aria-expanded=${this._expanded}>
                        <span class="sitemap-column-text">${this.header}</span>
                        <sbk-icon class="sitemap-column-icon" size="24" symbol=${this._expanded ? 'chevron-up' : 'chevron-down'}></sbk-icon>
                    </button>
                     <p class="sitemap-column-header sitemap-column-header-desktop">
                        ${this.header}
                    </p>
                </dt>
                <dd id="sitemap-panel"
                    aria-labelledby="accordion-header"
                    ?hidden=${!this._expanded}>
                    <ul>
                        <slot></slot>
                    </ul>
                </dd>
            </dl>
        `;
    }

    updated() {
        requestAnimationFrame(() => {
            const span = this.shadowRoot?.querySelector<HTMLElement>("span.sitemap-column-text");
            const icon = this.shadowRoot?.querySelector<HTMLElement>("sbk-icon.sitemap-column-icon");

            // Early exit if elements are not found
            if (!span || !icon) return;

            if (this.isIconOnNewLine(span, icon)) {
                this.applyStylesToSpan(span);
            }
        });
    }

    private isIconOnNewLine(span: HTMLElement, icon: HTMLElement): boolean {
        // Get computed line height safely
        const lineHeight = parseFloat(getComputedStyle(span).lineHeight) || 0;

        // Check if the icon is visually positioned on a new line relative to the span
        return icon.offsetTop - lineHeight > span.offsetTop;
    }

    private applyStylesToSpan(span: HTMLElement): void {
        // Apply inline styles or consider toggling a CSS class instead
        span.style.width = '50%';
        span.style.display = 'inline-block';
    }
}
